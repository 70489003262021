
import { useSelector } from "react-redux";
import { getAuthData } from "../redux/slices/AuthSlice";

const usePendo = () => {
  const authData = useSelector(getAuthData);
  const accountDetails = authData.accountDetails;
  const userDetails = authData.userDetails;
  const initializePendo = () => {
    const pendoApiKey = process.env.REACT_APP_PENDO_API_KEY;
    (function (p: any, e: any, n: any, d: string) {
      var v: any, w: any, x: any, y: any, z: any;
      const o = (p[d] = p[d] || {});
      o._q = o._q || [];
      v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
      for (w = 0, x = v.length; w < x; ++w)
        (function (m: any) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? "unshift" : "push"](
                [m].concat([].slice.call(arguments, 0))
              );
            };
        })(v[w]);
      y = e.createElement(n);
      y.async = true;
      y.src = "https://cdn.pendo.io/agent/static/" + pendoApiKey + "/pendo.js";
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, "script", "pendo");
    if (accountDetails && userDetails) {
      (window as any).pendo.initialize({
        visitor: {
          id: accountDetails.uuid,
          email: userDetails.email,
          full_name: userDetails.full_name,
          role: userDetails.is_owner,
          verified: userDetails.verified,
        },
        account: {
          id: accountDetails.id,
          name: accountDetails.company_name,
          planLevel: accountDetails.plan,
        },
      });
    }
  };

  return { initializePendo };
};

export default usePendo;

