import { Route, Routes, useLocation } from 'react-router-dom';
import { adminRoutes, privateRoutes, publicRoutes } from './routes/Index';
import DashboardLayout from '../components/layout/DashboardLayout';
import { Suspense, useEffect } from 'react';
import AuthProvider from '../components/auth-provider/AuthProvider';
import AdminDashboardLayout from '../components/layout/AdminDashboardLayout';
import usePendo from '../hooks/usePendo';

export interface RouteAttribute {
    id: string,
    path: string;
    element: JSX.Element;
    name?: string;
}

const RouterComponent = () => {
    const isAdminPath = window.location.pathname.includes('admin/');
    const { initializePendo } = usePendo();
    const { pathname } = useLocation();

    useEffect(() => {
        initializePendo()
    }, [pathname]);

    window.addEventListener("paste", function (e: any) {
        e.preventDefault();
        var text = (e.originalEvent || e).clipboardData.getData('text/plain');
        // insert text manually
        document.execCommand("insertHTML", false, text);
    });
    return (
        <Suspense fallback="Loading...">
            <Routes>
                <Route path='/' >
                    {publicRoutes.map((route: RouteAttribute) => <Route index key={route.id} {...route} />)}
                </Route>
                <Route path='/' element={
                    <AuthProvider>
                        <DashboardLayout />
                    </AuthProvider>
                }>
                    {privateRoutes.map((route: RouteAttribute) => <Route index key={route.id} {...route} />)}
                </Route>
                {
                    isAdminPath &&
                    <Route path='/' element={
                        <AuthProvider>
                            <AdminDashboardLayout />
                        </AuthProvider>
                    }>
                        {adminRoutes.map((route: RouteAttribute) => <Route index key={route.id} {...route} />)}
                    </Route>
                }
            </Routes>
        </Suspense>
    );
};

export default RouterComponent;